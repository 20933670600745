<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-my-stats-submenu v-if="showSubmenu !== false" has-demo v-on:demo="startDemo()"></q-my-stats-submenu>
        <v-row class="mx-7">
            <v-col class="pa-0" cols="12" v-if="showSubmenu !== false">
                <h3 v-if="agent">
                    {{agent.AgentName}} Lead Stats
                    <v-btn icon @click="viewAgent">
                        <v-icon>fas fa-arrow-circle-right</v-icon>
                    </v-btn>
                </h3>
                <v-row justify="end" v-if="canDispute">
                    <v-btn color="q_green_1 white--text" depressed transition="scroll-y-transition" @click="launchDisputeForm"><strong>Dispute</strong></v-btn><br>
                </v-row>
            </v-col>
            <v-row :class="!$vuetify.breakpoint.sm && 'flex-wrap-reverse'">
                <v-col cols="12" sm="8" md="9" lg="10" v-if="agent">
                    <v-row wrap>
                        <v-col class="pmx-3" cols="12" md="6" lg="3">
                            <q-score-card-a-leads ref="a_leads" :agent-code="agent_code" :filters="filters" key="a-leads" drill-down-route="Drilldown_ALeads" lead-type="A" v-on:most_current="updateMostCurrentA"></q-score-card-a-leads>
                        </v-col>
                        <v-col class="px-3" cols="12" md="6" lg="3">
                            <q-score-card-bonus-leads ref="b_leads" :agent-code="agent_code" :filters="filters" key="b-leads" drill-down-route="Drilldown_BonusLeads" lead-type="Bonus" v-on:most_current="updateMostCurrentB"></q-score-card-bonus-leads>
                        </v-col>
                        <v-col class="px-3" cols="12" md="6" lg="3">
                            <q-score-card-digital-leads
                                key="digital_leads"
                                :agent-code="agent_code"
                                :filters="filters"
                                @most_current="updateMostCurrentRequested"
                            />
                        </v-col>
                        <v-col class="px-3" cols="12" md="6" lg="3">
                            <q-score-card-recruiting-leads
                                key="recruiting_leads"
                                :agent-code="agent_code"
                                :filters="filters"
                                @most_current="updateMostCurrentRequested"
                            />
                        </v-col>
                        <v-col class="px-3" cols="12" md="6" lg="3">
                            <q-score-card-standing-l-h-requests
                                key="standing_lh_requests"
                                :agent-code="agent_code"
                                :filters="filters"
                                @most_current="updateMostCurrentRequested"
                            />
                        </v-col>
                        <v-col class="px-3" cols="12" md="6" lg="3" v-if="agent && (agent.LeadershipLevel == 'Key Leader' || agent.IsAgencyOwner || agent.AgentCode == 'SFG0000001')">
                            <q-score-card-unique-lead-takers :agent-code="agent_code" :filters="filters" key="unique-lead-takers"></q-score-card-unique-lead-takers>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="4" md="3" lg="2" v-if="agent">
                    <q-score-card-filters v-model="filters" :agent="agent" v-on:agent="changeAgent" :allow-agent-lookup="allowAgentLookup"></q-score-card-filters>
                </v-col>
            </v-row>
        </v-row>
    </v-container>
</template>
<script>
import QMyStatsSubmenu from '@/components/navigation/Submenus/QMyStatsSubmenu.vue';
import QScoreCardALeads from '@/components/stats/scorecards/QScoreCardALeads.vue';
import QScoreCardDigitalLeads from '@/components/stats/scorecards/QScoreCardDigitalLeads.vue';
import QScoreCardRecruitingLeads from '@/components/stats/scorecards/QScoreCardRecruitingLeads.vue';
import QScoreCardBonusLeads from '@/components/stats/scorecards/QScoreCardBonusLeads.vue';
import QScoreCardStandingLHRequests from '@/components/stats/scorecards/QScoreCardStandingLHRequests.vue';
import QScoreCardUniqueLeadTakers from '@/components/stats/scorecards/QScoreCardUniqueLeadTakers.vue';
import QScoreCardFilters from '@/components/stats/scorecards/QScoreCardFilters.vue';
import PromotionLevels from '@/store/PromotionLevels.json'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { isDisputeTimePeriod } from '@/components/utils/BusinessTimeDateDiff'

export default {
    props: ["showSubmenu", "allowAgentLookup"],
    name: "ScoreCards_Leads",
    data() {
        return {
            agentContractLevel: null,
            filters: {
                startDate: null,
                endDate: null,
                timePeriod: 'month',
                statType: null
            },
            a_lead_amount: null,
            a_lead_allocated: null,
            b_lead_amount: null,
            agent: null,
            requested_amount: null
        }
    },
    mounted: function() {
        this.getAgentContractLevel(this.agentCode)
    },
    computed: {
        'default_placed_goal': function() {
            var nextP = this.nextPromotionLevel(this.agentContractLevel);
            return nextP.APV
        },
        'default_uw_goal': function() {
            var nextP = this.nextPromotionLevel(this.agentContractLevel);
            return nextP.UniqueWriters
        },
        agent_code: function() {
            if (typeof this.$route.params.agentcode != 'undefined' && this.$route.params.agentcode != null) {
                return this.$route.params.agentcode;
            }
            return this.user.Agent.AgentCode;
        },
        donut_chart_data: function() {
            if (this.a_lead_amount != null && this.b_lead_amount) {
                return {
                    'A Leads': this.a_lead_amount,
                    'Bonus Leads': this.b_lead_amount,
                };
            }
            return null;
        },
        donut_chart_data_fulfillment: function() {
            if (this.a_lead_allocated != null && this.b_lead_amount) {
                return {
                    'A Leads': this.a_lead_allocated,
                    'Unfilled': this.requested_amount - this.a_lead_allocated,
                };
            }
            return null;
        },
        subtitle_date: function() {
            if (this.filters.endDate == null) {
                return this.monthFromDate(new Date())
            }
            return this.monthFromDate(this.filters.endDate)
        },
        canDispute: function() {
            return isDisputeTimePeriod()
        },
    },

    methods: {
        'startDemo': function() {
            this.$tours['DemoMyScorecard'].start()
        },
        nextPromotionLevel: function(role) {
            for (var i = 0; i < PromotionLevels.length; i++) {
                if (role == PromotionLevels[i].ContractLevel) {
                    if (i >= PromotionLevels.length - 1) {
                        return PromotionLevels[i];
                    }
                    return PromotionLevels[i + 1];
                }
            }
            return PromotionLevels[PromotionLevels.length - 1];
        },
        getAgentContractLevel(agentCode) {
            var g = this
            QuilityAPI.getAgentStateless(this.agent_code).then(function(json) {
                if (typeof json.ContractLevel != 'undefined') {
                    g.agent = json;
                    g.agentContractLevel = json.ContractLevel;
                    return
                }
            })
        },
        changeAgent(agentCode) {
            if (typeof agentCode == 'undefined') {
                this.$router.push('/my_stats/scorecard/leads/');
                return
            }
            this.$router.push('/my_stats/scorecard/leads/' + agentCode);
        },
        viewAgent: function() {
            this.$router.push('/agents/' + agent_code);
        },
        updateMostCurrentA: function(most_current) {
            this.a_lead_amount = most_current.Amount;
            this.a_lead_allocated = most_current.AllocatedAmount;
        },
        updateMostCurrentB: function(most_current) {
            this.b_lead_amount = most_current.Amount;
        },
        updateMostCurrentRequested: function(most_current) {
            this.requested_amount = most_current.Amount;
        },
        launchDisputeForm() {
            // open link to dispute form
            // housed in jot forms
            window.open('https://form.jotform.com/211966371426055', '_blank');
        },
    },

    watch: {
        'agent_code': function(newV) {
            var g = this
            g.getAgentContractLevel(newV)
        }
    },
    components: {
        QScoreCardALeads,
        QScoreCardBonusLeads,
        QScoreCardDigitalLeads,
        QScoreCardRecruitingLeads,
        QScoreCardStandingLHRequests,
        QScoreCardUniqueLeadTakers,
        QMyStatsSubmenu,
        QScoreCardFilters,
    }
}

</script>
