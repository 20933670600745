<template>
    <v-container fluid class="grey lighten-5 my-0 px-0 py-0">
        <q-my-stats-submenu v-if="showSubmenu !== false" has-demo v-on:demo="startDemo()"></q-my-stats-submenu>
        <v-row class="mx-7">
            <v-col class="pa-0" cols="12">
                <h3 v-if="stat_agent && !loading">
                    {{stat_agent.AgentName}} QuilityRx Stats
                </h3>
            </v-col>
            <v-progress-linear indeterminate v-if="loading"></v-progress-linear>

            <v-row :class="!$vuetify.breakpoint.sm && 'flex-wrap-reverse'">
                <v-col cols="12" sm="8" md="10" v-if="stat_agent">
                    <v-row wrap>
                        <v-col class="pmx-3" cols="12" md="6" lg="4">
                            <q-score-card-quility-rx-cards-sent ref="cards_sent" :agent-code="stat_agent.AgentCode" :filters="filters" key="cards_sent"></q-score-card-quility-rx-cards-sent>
                        </v-col>
                        <v-col class="pmx-3" cols="12" md="6" lg="4">
                            <q-score-card-quility-rx-codes-used ref="codes_used" :agent-code="stat_agent.AgentCode" :filters="filters" key="codes_used"></q-score-card-quility-rx-codes-used>
                        </v-col>
                        <v-col class="pmx-3" cols="12" md="6" lg="4">
                            <q-score-card-quility-rx-compensable ref="compensable" :agent-code="stat_agent.AgentCode" :filters="filters" key="compensable"></q-score-card-quility-rx-compensable>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="4" md="2" v-if="stat_agent">
                    <q-score-card-filters v-model="filters" :agent="stat_agent" v-on:agent="changeAgent" :allow-agent-lookup="true"></q-score-card-filters>
                </v-col>
            </v-row>
        </v-row>
    </v-container>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import Page from '@/CMS/views/index.vue'
import User from '@/store/Models/User'
import rules from '@/components/utils/ValidationRules'
import QScoreCardQuilityRxCardsSent from '@/components/stats/scorecards/QScoreCardQuilityRxCardsSent.vue';
import QScoreCardQuilityRxCodesUsed from '@/components/stats/scorecards/QScoreCardQuilityRxCodesUsed.vue';
import QScoreCardQuilityRxCompensable from '@/components/stats/scorecards/QScoreCardQuilityRxCompensable.vue';
import QScoreCardFilters from '@/components/stats/scorecards/QScoreCardFilters.vue';
import QMyStatsSubmenu from '@/components/navigation/Submenus/QMyStatsSubmenu.vue';

export default {
    props: ['showSubmenu', "allowAgentLookup"],
    data: function() {
        return {
            loading: false,
            stat_agent: null,
            ag: null,
            filters: {
                startDate: null,
                endDate: null,
                timePeriod: 'month',
                statType: null
            },
        }
    },
    mounted: function() {
        this.changeAgent(this.agent_code);
    },
    computed: {
        agent_code: function() {
            if (typeof this.$route.params.agentcode != 'undefined' && this.$route.params.agentcode != null) {
                return this.$route.params.agentcode;
            }
            return this.user.Agent.AgentCode;
        },
    },
    methods: {
        changeAgent(agentCode) {
            var g = this
            this.loading = true
            if (typeof agentCode == 'undefined') {
                agentCode = this.user.Agent.AgentCode
            }
            QuilityAPI.getAgentStateless(agentCode).then(function(json) {
                g.loading = false
                if (typeof json.ContractLevel != 'undefined') {
                    g.stat_agent = json;
                    return
                }
            }).catch(function(err) {
                g.loading = false
                console.log(err)
            })
        },
    },
    watch: {

    },
    components: {
        QMyStatsSubmenu,
        Page,
        QScoreCardFilters,
        QScoreCardQuilityRxCardsSent,
        QScoreCardQuilityRxCodesUsed,
        QScoreCardQuilityRxCompensable
    }
}

</script>
<style scoped>
</style>
