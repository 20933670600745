<template>
    <div cols="12" v-if="agent">
        <v-col>
            <h4>ENGAGE: Quility National Conference 2024</h4>
            <p>Sep 11 - 13, 2024 | Colorado Convention Center - Denver Colorado</p>
        </v-col>
        <v-row :no-gutters="$vuetify.breakpoint.smAndDown" :class="{ 'pa-3': $vuetify.breakpoint.mdAndUp }">
            <v-col cols="12">
                <v-card class="full-width" color="q_blue_1">
                    <h4 class="white--text pl-5 py-3 mb-0">Registration Stats</h4>
                </v-card>
            </v-col>
            <v-col class="pmx-3" cols="12" md="6" lg="3">
                <q-score-card-registrations :agent-code="agent_code" :filters="personalFilters" key="submitted" drill-down-route="Drilldown_Submitted"></q-score-card-registrations>
            </v-col>
            <v-col v-if="agent.HasManagerAccess" class="pmx-3" cols="12" md="6" lg="3">
                <q-score-card-baseshop-registrations :agent-code="agent_code" :filters="baseshopFilters" key="submitted" drill-down-route="Drilldown_Submitted"></q-score-card-baseshop-registrations>
            </v-col>
            <v-col class="pmx-3" cols="12" md="6" lg="3">
                <q-score-card-total-agency-registrations :agent-code="agent_code" :filters="totalagencyfilters" key="submitted" drill-down-route="Drilldown_Submitted"></q-score-card-total-agency-registrations>
            </v-col>
            <v-col v-if="agent.IsSFGDirect" class="pmx-3" cols="12" md="6" lg="3">
                <q-score-card-direct-agency-registrations :agent-code="agent_code" :filters="directagencyfilters" key="submitted" drill-down-route="Drilldown_Submitted"></q-score-card-direct-agency-registrations>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import QScoreCardRegistrations from '@/components/stats/scorecards/QScoreCardRegistrations.vue'
import QScoreCardBaseshopRegistrations from '@/components/stats/scorecards/QScoreCardBaseshopRegistrations.vue'
import QScoreCardTotalAgencyRegistrations from '@/components/stats/scorecards/QScoreCardTotalAgencyRegistrations.vue'
import QScoreCardDirectAgencyRegistrations from '@/components/stats/scorecards/QScoreCardDirectAgencyRegistrations.vue'
import PromotionLevels from '@/store/PromotionLevels.json'
import { debounce } from 'vue-debounce'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import moment from 'moment'

export default {
    data() {
        return {
            agentContractLevel: null,
            personalFilters: {
                startDate: null,
                endDate: null,
                timePeriod: 'month',
                statType: 'personal'
            },
            baseshopFilters: {
                startDate: null,
                endDate: null,
                timePeriod: 'month',
                statType: 'baseshop'
            },
            totalagencyfilters: {
                startDate: null,
                endDate: null,
                timePeriod: 'month',
                statType: 'totalagency'
            },
            directagencyfilters: {
                startDate: null,
                endDate: null,
                timePeriod: 'month',
                statType: 'directagency'
            },
            agent: null,
            datePeriod: { label: 'Current Month', 'period': 'month' }
        }
    },
    mounted: function() {
        this.getAgentContractLevel(this.agentCode)
    },
    computed: {
        'default_placed_goal': function() {
            var nextP = this.nextPromotionLevel(this.agentContractLevel);
            return nextP.APV
        },
        'default_uw_goal': function() {
            var nextP = this.nextPromotionLevel(this.agentContractLevel);
            return nextP.UniqueWriters
        },
        agent_code: function() {
            if (typeof this.$route.params.agentcode != 'undefined' && this.$route.params.agentcode != null) {
                return this.$route.params.agentcode;
            }
            return this.user.Agent.AgentCode;
        },
        'time_period': function() {
            if (this.datePeriod.period == 'week') {
                return 'weekly'
            }
            if (this.datePeriod.period == 'month') {
                return 'monthly'
            }
            if (this.datePeriod.period == 'year') {
                return 'yearly'
            }
        }
    },

    methods: {
        'startDemo': function() {
            this.$tours['DemoMyScorecard'].start()
        },
        nextPromotionLevel: function(role) {
            for (var i = 0; i < PromotionLevels.length; i++) {
                if (role == PromotionLevels[i].ContractLevel) {
                    if (i >= PromotionLevels.length - 1) {
                        return PromotionLevels[i];
                    }
                    return PromotionLevels[i + 1];
                }
            }
            return PromotionLevels[PromotionLevels.length - 1];
        },
        getAgentContractLevel(agentCode) {
            var g = this
            QuilityAPI.getAgentStateless(this.agent_code).then(function(json) {
                if (typeof json.ContractLevel != 'undefined') {
                    g.agent = json;
                    g.agentContractLevel = json.ContractLevel;
                    return
                }
            })
        },
        changeAgent(agentCode) {
            if (typeof agentCode == 'undefined') {
                this.$router.push('/my_stats/scorecard/new_business/');
                return
            }
            this.$router.push('/my_stats/scorecard/new_business/' + agentCode);
        },
        viewAgent: function() {
            this.$router.push('/agents/' + this.agent_code);
        }
    },

    watch: {
        'agent_code': function(newV) {
            var g = this
            g.getAgentContractLevel(newV)
        }
    },
    components: {
        QScoreCardRegistrations,
        QScoreCardBaseshopRegistrations,
        QScoreCardTotalAgencyRegistrations,
        QScoreCardDirectAgencyRegistrations

    }
}

</script>
}
