<!-- This needs refactoring, it's confusing because we no longer need a "minimal" version anymore
Eventually this should be replaced with the score card design  -->
<template>
    <v-container fluid style="position:relative">
        <v-row class="pa-0">
            <v-col cols="12" class="pt-0">
                <v-row wrap>
                    <v-col cols="12" class="pt-0">
                        <div class="q-display-2 inline-form-left full-width">
                            Personal Production Stats
                            <q-tooltip-details>
                                Stats updated weekly, but placed data is updated monthly during the following month when data is available from the carriers.
                                <span v-if="agent.IsAgencyOwner"> These are your personal production stats.</span>
                            </q-tooltip-details>
                        </div>
                        <div>
                            <p class="body-2 full-width">Quility HQ data is synced nightly and does not provide real-time data. Please reference Opt! for the most up-to-date metrics.</p>
                        </div>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                        <div class="inline-form-left">
                            <q-business-time-period-select :disabled="loading" v-model="datePeriod" label="Time Period" :allow-current="true" stateKey="stat"></q-business-time-period-select>
                        </div>
                    </v-col>
                    <v-col id="v-prod-1" cols="12" :sm="cols">
                        <q-stats-submitted-chart :agent="agent" :start-date="datePeriod.startDateFormat" :end-date="datePeriod.endDateFormat" :time-period="datePeriod.period" :goal="0" stat-type="PersonalProduction"></q-stats-submitted-chart>
                        <div class="stat pb-4">
                            <q-stats-close-ratio-chart :agent="agent" :start-date="datePeriod.startDateFormat" :end-date="datePeriod.endDateFormat" :time-period="datePeriod.period" :goal="0" stat-type="PersonalProduction"></q-stats-close-ratio-chart>
                            <p class="stat-label">Submitted business {{datePeriod.period}} is {{datePeriod.startDateFormat}} through {{datePeriod.endDateFormat}}. Close Ratio is 6-week rolling average from {{datePeriod.startDateFormat}}. Current stats are synced with Opt nightly.</p>
                        </div>
                    </v-col>
                    <v-col cols="12" :sm="cols" class="px-4">
                        <q-stats-placed-chart :agent="agent" :start-date="datePeriod.startDateFormat" :end-date="datePeriod.endDateFormat" :time-period="datePeriod.period" :goal="0" stat-type="PersonalProduction"></q-stats-placed-chart>
                    </v-col>
                </v-row>
            </v-col>
            <v-col id="v-monthly-3" cols="12" v-if="stats.salesData.length > 0 && !loading">
                <v-list-item one-line>
                    <v-list-item-content>
                        <v-list-item-title class="q-display-2">Monthly Sales Data
                            <q-tooltip-details>
                                <span class="q-subtitle">This stat is updated weekly.<br>Note that historical data for 'submits' will be available from May 2020 onward. Placed data is updated monthly during the following month when data is available from the carriers.</span>
                            </q-tooltip-details>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <q-monthly-sales-chart :data="stats.salesData" :placed-data="stats.placedData" type="PersonalProduction"></q-monthly-sales-chart>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QProgressStat from '@/components/stats/QProgressStat.vue';
import QComparisonStat from '@/components/stats/QComparisonStat.vue';
import QMonthlySalesChart from '@/components/charts/QMonthlySalesChart.vue';
import QPromotionTrackerChart from '@/components/charts/QPromotionTrackerChart.vue';
import QTooltipDetails from '@/components/utils/QTooltipDetails.vue';

import User from '@/store/Models/User'
import PromotionLevels from '@/store/PromotionLevels.json'
import QBusinessTimePeriodSelect from '@/components/utils/QBusinessTimePeriodSelect.vue';

import QStatsSubmittedChart from '@/components/charts/QStatsSubmittedChart.vue';
import QStatsCloseRatioChart from '@/components/charts/QStatsCloseRatioChart.vue';
import QStatsPlacedChart from '@/components/charts/QStatsPlacedChart.vue';
export default {
    props: {
        'agent': {
            type: Object,
            default: function() {
                return {}
            },
        },
    },
    data: function() {
        return {
            stats: {
                "rollingCloseRatio6weeks": {},
                "rollingCloseRatioPrevious6weeks": {},
                "weeklyProduction": {},
                "weeklyProductionPrevious": {},
                "salesData": [],
                "placedData": [],
            },
            loading: false,
            datePeriod: { label: 'Current Month' },
        }
    },
    mounted: function() {
        this.loadData()
    },
    computed: {
        promotionLevelAPV: function() {
            return this.promotionLevel().APV;
        },
        promotionLevelPlaced: function() {
            return this.promotionLevel().MinimumAPV;
        },
        promotionLevelUW: function() {
            return this.promotionLevel().UniqueWriters;
        },
        cols: function() {
            if (this.minimal) {
                return 12
            }
            return 6
        },
    },
    methods: {
        promotionLevel: function() {

            //ensure that contract level is set
            // before performing search
            const contractLevel = this.agent.ContractLevel ? this.agent.ContractLevel : this.agent.Agent.ContractLevel

            for (var i = 0; i < PromotionLevels.length; i++) {
                if (contractLevel == PromotionLevels[i].CommissionLevel) {
                    return PromotionLevels[i]
                }
            }
            return {};
        },
        loadData: function() {
            if (typeof this.agent == 'undefined') {
                return;
            }
            var g = this
            this.loading = true;

            var params = {}; //you can add "type" to get a certain type of stats - baseshop or total agency or production

            var params = {
                type: 'PersonalProduction',
                startDate: null,
                endDate: null,
                timePeriod: 'month'
            };

            QuilityAPI.getStatRangeCombined(this.agent.AgentCode, params).then(function(json) {
                g.loading = false;
                g.$set(g, 'stats', json.data);
            }).catch(function(err) {
                if (typeof err == 'undefined') {
                    return;
                }
                if (err.message == "Aborted") {
                    return;
                }
                g.loading = false;
                if (err.errors == "Page not found") {
                    g.showError("You don't appear to have any stats.");
                    return
                }
                g.showError(err.message);
            })
        },
        loadAgentStats(agentcode) {
            this.$router.push('/my_stats/agents/' + agentcode)
        },
        'startDemo': function() {
            this.$tours['DemoMyStats'].start()
        }
    },
    watch: {
        'agent.AgentCode': function(newV) {
            this.stats = {
                "rollingCloseRatio6weeks": {},
                "rollingCloseRatioPrevious6weeks": {},
                "monthlyProduction": {},
                "monthlyProductionPrevious": {},
                "salesData": [],
            }
            this.$nextTick(this.loadData);
        },
    },
    components: {
        QProgressStat,
        QComparisonStat,
        QMonthlySalesChart,
        QPromotionTrackerChart,
        QTooltipDetails,
        QBusinessTimePeriodSelect,

        QStatsSubmittedChart,
        QStatsCloseRatioChart,
        QStatsPlacedChart
    }
}

</script>
<style>
.v-tour__target--highlighted.v-tour__target--relative {
    z-index: 1;
}

</style>
