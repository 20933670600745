<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-my-stats-submenu v-if="showSubmenu !== false" has-demo v-on:demo="startDemo()"></q-my-stats-submenu>
        <v-row class="mx-7">
            <v-col class="pa-0" cols="12" v-if="showSubmenu !== false">
                <h3 v-if="agent">
                    {{agent.AgentName}}
                    <v-btn icon @click="viewAgent">
                        <v-icon>fas fa-arrow-circle-right</v-icon>
                    </v-btn>
                    <v-row justify="end" v-if="canDispute">
                        <v-btn color="q_green_1 white--text" depressed transition="scroll-y-transition" @click="launchDisputeForm"><strong>Dispute</strong></v-btn><br>
                    </v-row>
                </h3>
            </v-col>

            <v-row :class="!$vuetify.breakpoint.sm && 'flex-wrap-reverse'">
                <v-col cols="12" sm="8" md="9" lg="10" v-if="agent">
                    <v-row wrap>
                        <!--<v-col class="pmx-3" cols="12" md="6" lg="3">
                            <q-score-card-total-recruits :agent-code="agent_code" :filters="filters" key="total-recruits"></q-score-card-total-recruits>
                        </v-col>//-->
                        <v-col class="pmx-3" cols="12" md="6" lg="3">
                            <q-score-card-new-recruits :agent-code="agent_code" :filters="filters" key="total-recruits"></q-score-card-new-recruits>
                        </v-col>
                        <v-col class="px-3" cols="12" md="6" lg="3" v-if="agent_code != 'SFG0000001'">
                            <q-score-card-unique-builders :agent-code="agent_code" :filters="filters" :default-goal="default_placed_goal" key="unique-builders"></q-score-card-unique-builders>
                        </v-col>
                        <v-col class="px-3" cols="12" md="6" lg="3" v-if="agent && (agent.LeadershipLevel == 'Key Leader' || agent.IsAgencyOwner)">
                            <q-score-card-new-writers :agent-code="agent_code" :filters="filters" key="new-writers">
                            </q-score-card-new-writers>
                        </v-col>
                        <v-col class="px-3" cols="12" md="6" lg="3" v-if="agent && (agent.LeadershipLevel == 'Key Leader' || agent.IsAgencyOwner || agent.AgentID==458424)">
                            <q-score-card-unique-writers :agent-code="agent_code" :filters="filters" :default-goal="default_uw_goal" key="unique-writers" card-style="recruiting"></q-score-card-unique-writers>
                        </v-col>
                        <!-- <v-col class="px-3" cols="12" md="6" lg="3">
                            <q-score-card-average-days-to-submit :agent-code="agent_code" :filters="filters" key="average-days-to-submit">
                            </q-score-card-average-days-to-submit>
                        </v-col> -->
                        <v-col class="px-3" cols="12" md="6" lg="3">
                            <q-score-card-average-apps-per-agent :agent-code="agent_code" :filters="filters" key="average-apps-per-agent"></q-score-card-average-apps-per-agent>
                        </v-col>
                        <v-col class="px-3" cols="12" v-if="agent">
                            <q-new-agent-apps-data-table :agent="agent" title="Ready to Write"></q-new-agent-apps-data-table>
                        </v-col>
                        <v-col class="px-3" cols="12" v-if="agent">
							<OnboardingPortalStats :agent-code="agent_code" :filters="filters" />
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="4" md="3" lg="2" v-if="agent">
                    <q-score-card-filters v-model="filters" :agent="agent" v-on:agent="changeAgent" :allow-agent-lookup="allowAgentLookup"></q-score-card-filters>
                </v-col>
            </v-row>
        </v-row>
    </v-container>
</template>
<script>
import QMyStatsSubmenu from '@/components/navigation/Submenus/QMyStatsSubmenu.vue';
import QScoreCardTotalRecruits from '@/components/stats/scorecards/QScoreCardTotalRecruits.vue';
import QScoreCardNewRecruits from '@/components/stats/scorecards/QScoreCardNewRecruits.vue';
import QScoreCardUniqueBuilders from '@/components/stats/scorecards/QScoreCardUniqueBuilders.vue';
import QScoreCardNewWriters from '@/components/stats/scorecards/QScoreCardNewWriters.vue';
import QScoreCardAverageDaysToSubmit from '@/components/stats/scorecards/QScoreCardAverageDaysToSubmit.vue';
import QScoreCardAverageAppsPerAgent from '@/components/stats/scorecards/QScoreCardAverageAppsPerAgent.vue';
import QScoreCardFilters from '@/components/stats/scorecards/QScoreCardFilters.vue';
import QNewAgentAppsDataTable from '@/components/datatables/QNewAgentAppsDataTable.vue';
import QScoreCardUniqueWriters from '@/components/stats/scorecards/QScoreCardUniqueWriters.vue';
import PromotionLevels from '@/store/PromotionLevels.json'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { isDisputeTimePeriod } from '@/components/utils/BusinessTimeDateDiff'
import OnboardingPortalStats from './OnboardingPortalStats.vue'

export default {
    props: ["showSubmenu", "allowAgentLookup"],
    data() {
        return {
            agentContractLevel: null,
            filters: {
                startDate: null,
                endDate: null,
                timePeriod: 'month',
                statType: null
            },
            agent: null
        }
    },
    mounted: function() {
        this.getAgentContractLevel(this.agentCode)
    },
    computed: {
        'default_placed_goal': function() {
            var nextP = this.nextPromotionLevel(this.agentContractLevel);
            return nextP.APV
        },
        'default_uw_goal': function() {
            var nextP = this.nextPromotionLevel(this.agentContractLevel);
            return nextP.UniqueWriters
        },
        agent_code: function() {
            if (typeof this.$route.params.agentcode != 'undefined' && this.$route.params.agentcode != null) {
                return this.$route.params.agentcode;
            }
            return this.user.Agent.AgentCode;
        },
        canDispute: function() {
            return isDisputeTimePeriod()
        },
    },

    methods: {
        'startDemo': function() {
            this.$tours['DemoMyScorecard'].start()
        },
        changeAgent(agentCode) {
            if (typeof agentCode == 'undefined') {
                this.$router.push('/my_stats/scorecard/recruiting/');
                return
            }
            this.$router.push('/my_stats/scorecard/recruiting/' + agentCode);
        },
        viewAgent: function() {
            this.$router.push('/agents/' + agent_code);
        },
        getAgentContractLevel(agentCode) {
            var g = this
            QuilityAPI.getAgentStateless(this.agent_code).then(function(json) {
                if (typeof json.ContractLevel != 'undefined') {
                    g.agent = json;
                    g.agentContractLevel = json.ContractLevel;
                    return
                }
            })
        },
        nextPromotionLevel: function(role) {
            for (var i = 0; i < PromotionLevels.length; i++) {
                if (role == PromotionLevels[i].ContractLevel) {
                    if (i >= PromotionLevels.length - 1) {
                        return PromotionLevels[i];
                    }
                    return PromotionLevels[i + 1];
                }
            }
            return PromotionLevels[PromotionLevels.length - 1];
        },
        launchDisputeForm() {
            // open link to dispute form
            // housed in jot forms
            window.open('https://form.jotform.com/211966371426055', '_blank');
        },
    },

    watch: {
        'agent_code': function(newV) {
            var g = this
            g.getAgentContractLevel(newV)
        }
    },
    components: {
        QScoreCardTotalRecruits,
        QScoreCardUniqueBuilders,
        QScoreCardNewWriters,
        QScoreCardAverageDaysToSubmit,
        QScoreCardAverageAppsPerAgent,
        QMyStatsSubmenu,
        QScoreCardFilters,
        QNewAgentAppsDataTable,
        QScoreCardNewRecruits,
        QScoreCardUniqueWriters,
		OnboardingPortalStats,
    }
}

</script>
